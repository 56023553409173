import React, { useState } from "react";
import Sidebar from "./Sidebar";
import MatchingManual from "./MatchingManual"; // Importing Manual and MatchingManual components
import {
  handleRefineMatches,
  handleGetManualsByImages,
} from "../../api/backend"; // Assuming handleRefineMatches returns some response
import { useTranslation } from "react-i18next";
import ExtractedImages from "../PdfImageExtractor/ExtractedImages";
import { Match, Metadata, Image } from "../PdfImageExtractor/ExtractedImages";
import { Row, Col, Form } from "react-bootstrap";
import { useExtractedImagesState } from "../../store/hooks";
import {
  Manual,
  ManualItem,
  ImageManual,
  ImageManualItem,
} from "../../utils/manualUtils";
interface SpecialToolProps {
  tools: string[];
  onBack: () => void;
  manuals: Manual[];
}

const SpecialTool: React.FC<SpecialToolProps> = ({
  tools,
  onBack,
  manuals,
}) => {
  const [filteredManuals, setFilteredManuals] = useState<Manual[]>(manuals);
  const [imageManuals, setimageManuals] = useState<ImageManual[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const { setDisplayImageManuals } = useExtractedImagesState();
  const { t } = useTranslation();
  const onFilterMatches = async (filteredTools: string[], topK: number) => {
    setIsLoading(true);
    const stringifiedTools = JSON.stringify(filteredTools);
    const manualResponse = await handleRefineMatches(stringifiedTools, topK);
    if (manualResponse.length > 0) {
      const matchingManuals: Manual[] = [];
      manualResponse.forEach((item: ManualItem) => {
        if (item) {
          // const item = manualResponse[id];
          const manual: Manual = {
            manualName: item.manualName,
            manualId: item.manualID,
            matchedTools: item.matchedSpecialTools
              .split(", ")
              .map((tool: string) => tool.trim()),
            additionalTools: item.additionalSpecialTools
              .split(", ")
              .map((tool: string) => tool.trim()),
            missingTools: item.missingSpecialTools
              .split(", ")
              .map((tool: string) => tool.trim()),
            itemMpText: item.itemMpText,
          };
          matchingManuals.push(manual);
        }
      });
      setFilteredManuals(matchingManuals);
    }
    setIsLoading(false);
  };

  const onGetManualsByImages = async (fileNames: string[], topK: number) => {
    setIsLoading(true);
    const manualResponse = await handleGetManualsByImages(fileNames, topK);
    if (manualResponse.length > 0) {
      const _imageManuals: ImageManual[] = [];
      manualResponse.forEach((item: ImageManualItem) => {
        if (item) {
          // const item = manualResponse[id];
          const manual: ImageManual = {
            manualName: item.manualName,
            manualId: item.manualId,
            itemMpText: item.itemMpText,
            imageFileNameCount: item.imageFileNameCount,
          };
          _imageManuals.push(manual);
        }
      });
      setimageManuals(_imageManuals);
      setDisplayImageManuals(true);
    }
    setIsLoading(false);
  };

  const handleDownload = () => {
    const csvContent = convertToCSV(filteredManuals);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "matchingManuals.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (data: Manual[]) => {
    const headers = [
      "ManualId",
      "ManualName",
      "ItemMpText",
      "MatchedTools",
      "AdditionalTools",
      "MissingTools",
    ];
    const rows = data.map((manual) => [
      manual.manualId,
      manual.manualName,
      manual.itemMpText,
      manual.matchedTools.join(", "),
      manual.additionalTools.join(", "),
      manual.missingTools.join(", "),
    ]);

    const csvRows = [
      headers.join(";"), // header row first
      ...rows.map((row) => row.join(";")), // data rows
    ];

    return csvRows.join("\n");
  };

  const handleToggle = (e: any) => {
    setShowImages(e.target.checked);
  };

  return (
    <div>
      <Row className="mb-4">
        <Col xs={8} />
        <Col xs={3} className="d-flex justify-content-end">
          <Form className="toggle-container">
            <Form.Check
              type="switch"
              id="custom-switch"
              label={t("SpecialTool.ToogleButton")}
              onChange={handleToggle}
              checked={showImages}
            />
          </Form>
        </Col>
        <Col xs={2} />
      </Row>
      <Sidebar
        tools={tools}
        onBack={onBack}
        onFilterMatches={onFilterMatches}
        handleDownload={handleDownload}
        isLoading={isLoading}
        showImages={showImages}
        onGetManualsByImages={onGetManualsByImages}
      />

      <div className="fixed-height">
        {showImages ? (
          <ExtractedImages imageManuals={imageManuals} />
        ) : (
          <MatchingManual manuals={filteredManuals} />
        )}
      </div>
    </div>
  );
};

export default SpecialTool;
