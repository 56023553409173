// manualUtils.ts
import { toast } from "react-toastify";
import { handleGetManualHtml } from "../api/backend";

export interface Manual {
  manualName: string;
  manualId: string;
  matchedTools: string[];
  additionalTools: string[];
  missingTools: string[];
  itemMpText: string;
}

export interface ImageManual {
  manualName: string;
  manualId: string;
  itemMpText: string;
  imageFileNameCount: number;
}

export type ImageManualItem = {
  manualName: string;
  manualId: string;
  itemMpText: string;
  imageFileNameCount: number;
};

export type ManualItem = {
  manualName: string;
  matchedSpecialTools: string;
  additionalSpecialTools: string;
  missingSpecialTools: string;
  itemMpText: string;
  manualID: string;
};

export const handleManualClick = async (
  manualId: string,
  setLoading: (loading: boolean) => void,
  t: (key: string) => string
) => {
  try {
    setLoading(true);
    let htmlResponse = await handleGetManualHtml(manualId);
    if (htmlResponse) {
      const newWindow = window.open("", "_blank");
      if (newWindow) {
        newWindow.document.open();
        newWindow.document.write(htmlResponse);
        newWindow.document.close();
      }
    } else {
      toast.warn(t("Manual.NotFound"), {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    toast.error(t("Manual.OpeningError"), {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } finally {
    setLoading(false); // Hide spinner
  }
};

export const copyManualIdToClipboard = (manualId: string, message: string) => {
  toast(message, { type: "success" });
  navigator.clipboard.writeText(manualId);
};

export const copyFilenameToClipboard = (filename: string, message: string) => {
  filename = filename.replace('.ai-OEM', '').replace(".ai-Single", "").replace(".ai-TA", "");
  toast(message.replace("{}", filename), { type: "success"})
  navigator.clipboard.writeText(filename)
};
