// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_ssm_param_rmi_company_name": "/special-tools/feature/rmi-service-company",
    "aws_ssm_param_rmi_secret_name": "/special-tools/feature/rmi-service-password",
    "aws_ssm_param_rmi_user_name": "/special-tools/feature/rmi-service-username",
    "aws_ssm_param_rmi_type_id": "/special-tools/feature/rmi-service-type-id",
    "RumUserMonitoring": {
        "RumMonitorId": "cd92f805-1663-43e4-b01a-c6487ca58a37",
        "RumRegion": "eu-central-1",
        "RumTelemetries": "errors,http"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:c7a3af8f-8286-4503-81bc-ab33aea8b688",
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": [],
        "passwordPolicyMinLength": 8
    },
    "oauth": {
        "redirectSignOut": "http://localhost:3000/,https://feature-continue.d1dalb5kmxgzdl.amplifyapp.com/,https://feature-continue.rmi.sandbox.imagesimilarity.tecalliance.net/,https://rmi.sandbox.imagesimilarity.tecalliance.net/",
        "domain": "tecrmi-auth-feature-continue.auth.eu-central-1.amazoncognito.com",
        "responseType": "code",
        "redirectSignIn": "http://localhost:3000/,https://feature-continue.d1dalb5kmxgzdl.amplifyapp.com/,https://feature-continue.rmi.sandbox.imagesimilarity.tecalliance.net/,https://rmi.sandbox.imagesimilarity.tecalliance.net/",
        "scope": [
            "openid",
            "profile",
            "email"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_user_pools_id": "eu-central-1_98vVHV6WA",
    "aws_user_pools_web_client_id": "7q89scnqk2o6d8g4m253fg1kbf",
    "aws_cognito_region": "eu-central-1",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_custom_provider": "TecRMIfeatureconoAzureAD7F838634",
    "aws_cognito_social_providers": [],
    "aws_appsync_apiKey": "da2-er4vz3xzonavtpvibykq6l7kgi",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_graphqlEndpoint": "https://5k6dqartancivcr2ycp2o5744e.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_user_files_s3_bucket": "tecrmi-feature-continue-frontend",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "backend": {
        "region": "eu-north-1",
        "bucket_name": "tecrmi-manuals-feature"
    },
    "amplify_appid": "d1dalb5kmxgzdl",
    "aws_project_region": "eu-central-1"
};

export default awsmobile;
